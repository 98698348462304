.main--home {
  box-sizing: border-box;
  margin-top: 2.5rem;
}

.home__logo {
  display: none;
  position: fixed;
  top: 1px;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  background-color: rgba($c-green, 0.7);
  z-index: 10;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: auto;
  }

  @include bp-m() {
    img {
      width: 60%;
    }
  }
  @include bp-s() {
    img {
      width: 80%;
    }
  }
}

.home__grid {
  display: grid;
  box-sizing: border-box;
  padding: 0.5rem;
  gap: 4px;
  grid-template-columns: repeat(4, 1fr);
  // grid-auto-rows: 25vw;
  position: relative;
  z-index: 0;
  background: $c-lightgrey;

  &__block {
    box-sizing: border-box;
    position: relative;
    height: 35vw;

    &--c1 {
      grid-column: span 1;
      font-size: 1vw;
    }
    &--c2 {
      grid-column: span 2;
      font-size: 2vw;
    }
    &--c3 {
      grid-column: span 3;
      font-size: 3vw;
    }
    &--c4 {
      grid-column: span 4;
      font-size: 4vw;
    }

    &--sm {
      font-size: 1vw;
    }
    &--md {
      font-size: 3vw;
    }
    &--lg {
      font-size: 4vw;
    }
    // &--r1 {
    //   grid-row: span 1;
    // }
    // &--r2 {
    //   grid-row: span 2;
    // }
    // &--r4 {
    //   grid-row: span 4;
    // }
  }
  &__wrap {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 1vw;
    text-align: center;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $c-green;
      mix-blend-mode: multiply;
      z-index: 1;
      opacity: 0;
      transition: 0.5s opacity;
    }
  }
  &__text {
    font-size: inherit;
    color: #000;

    &--overlay {
      display: block;
      position: absolute;
      z-index: 1;
      color: #fff;
      bottom: 0;
      width: 100%;
      left: 0;
      box-sizing: border-box;
      padding: 0.5rem;
      text-align: left;
      opacity: 0;
      font-size: 1vw;
      transition: 0.5s opacity;
    }
  }
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(1);
  }

  @include bp-s() {
    grid-template-columns: repeat(4, 1fr);

    &__text--overlay {
      font-size: 3vw;
    }
    &__block {
      height: 50vw;

      &--c1 {
        grid-column: span 2;
        font-size: 3vw;
      }
      &--c2 {
        font-size: 3vw;
      }
      &--c3 {
        grid-column: span 4;
        font-size: 4vw;
      }
      &--c4 {
        font-size: 6vw;
      }

      &--sm {
        font-size: 3vw;
      }
      &--md {
        font-size: 4vw;
      }
      &--lg {
        font-size: 6vw;
      }
    }
  }
}

a.home__grid__wrap:hover::before {
  opacity: 1;
  transition: 0.2s opacity;
}
a.home__grid__wrap:hover .home__grid__text--overlay {
  opacity: 1;
  transition: 0.2s opacity;
}
