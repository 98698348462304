.main--table {
  background-color: $c-lightgrey;
  min-height: calc(100vh - 2.5rem);
  margin-top: 2.5rem;
  // border-top: 1px solid #000;
  padding-bottom: 4rem;

  @include bp-s() {
    margin-top: 2rem;
    padding-top: 2rem;
    min-height: calc(100vh - 2rem);
    border-top: none;
  }
}

.table-content {
  width: 100%;
  letter-spacing: 0;
  border-bottom: 1px solid #ccc;

  &--publications {
    table-layout: fixed;

    th {
      &:nth-child(1) {
        width: 36%;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 8%;
      }
      &:nth-child(5) {
        width: 8%;
      }
      &:nth-child(6) {
        width: 8%;
      }
    }

    @include bp-s() {
      margin-top: 2rem;
    }
  }
  &--news {
    table-layout: fixed;

    th {
      &:nth-child(1) {
        width: 44%;
      }
      &:nth-child(2) {
        width: 34%;
      }
      &:nth-child(3) {
        width: 14%;
      }
      &:nth-child(4) {
        width: 8%;
      }
    }
  }

  tr {
    &.head {
      border-top: none;

      @include bp-s() {
        display: none;
      }
    }
  }
  th {
    color: $c-grey;
    padding: 0.5rem 0.5rem 2rem 0;
    text-align: left;

    &:first-child {
      padding-left: 2rem;
    }
    &:last-child {
      padding-right: 2rem;
    }

    @include bp-s() {
      padding-top: 1rem;
      &:first-child {
        padding-left: 1rem;
      }
      &:last-child {
        padding-right: 1rem;
      }
    }

    a {
      color: inherit;
    }
    &.right {
      text-align: right;
    }
    &.active {
      color: #000;
    }
  }
  td {
    color: inherit;
    padding: 0.3rem 0.5rem 0.8rem 0;
    text-align: left;
    // font-size: 0.7rem;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-child {
      padding-left: 2rem;
    }
    &:last-child {
      padding-right: 2rem;
    }

    @include bp-s() {
      &:first-child {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    &.right {
      text-align: right;
    }
    a {
      color: inherit;

      &:hover {
        color: inherit;
      }
    }
  }

  .icon-active {
    display: block;
    width: 10px;
    height: 10px;
    background-color: #a5a5a5;
    border-radius: 99%;
  }

  &__parent {
    border-top: 1px solid #ccc;
    white-space: nowrap;
    color: $c-grey;
    transition: 0.2s background-color, 0.2s border-color, 0.2s color;

    &--inactive {
      background-color: #fefefe;
    }

    &.popup {
      cursor: pointer;

      &:hover {
        background-color: $c-green;
        color: #000;
        // border-top-color: #000;

        .icon-active {
          background-color: #000;
        }
      }
    }

    &.is-active {
      background-color: #fff;
      color: #000;
      // border-color: #000;

      &:hover {
        background-color: #fff;
        color: #000;
      }
      .icon-active {
        background-color: #000;
      }
    }

    @include bp-s() {
      white-space: normal;
    }
  }
  &__child {
    border-top: none;
    background-color: #fff;
    color: #000;
    white-space: normal;
    display: none;
    overflow: hidden;

    &.is-visible {
      display: table-row;
    }

    td {
      padding: 0 1rem 0 0;
      vertical-align: top;
      box-sizing: border-box;

      .td__inner {
        overflow: hidden;
        position: relative;

        p:last-child,
        ul:last-child {
          padding-bottom: 2rem;
        }

        .td__links {
          // font-size: 0.7rem;
        }
      }
      table.td__infotable {
        margin: 1rem 0;
        width: 100%;
        line-height: 1.4;
        max-width: 35rem;

        td:first-child {
          padding-left: 0;
          // width: 20%;
          width: 12rem;
        }
        td {
          font-size: 0.7rem;
          // padding-bottom: .1rem;
        }
      }
    }

    .morelink {
      display: block;
      color: #888;

      &:hover {
        color: #000;
      }
      &:last-child {
        margin-bottom: 1rem;
      }
    }
    .morelink + p {
      margin-top: 1rem;
    }
    p + .morelink {
      margin-top: 1rem;
    }

    p {
      font-size: 1rem;
      color: inherit;
      max-width: 35rem;
      letter-spacing: 0.02em;

      a {
        color: $c-grey;
      }
      strong {
        font-weight: normal;
      }
    }
    ul {
      font-size: 1rem;

      a {
        color: $c-grey;
      }

      li {
        padding-left: 1.5rem;
        position: relative;

        &::before {
          content: "–";
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    p + p,
    p + ul,
    ul + p {
      margin-top: 1rem;
    }

    img {
      max-width: 100%;
      max-height: 450px;
      margin-bottom: 1rem;

      @include bp-s() {
        max-height: 250px;
      }
    }
  }

  &__title {
    width: 40%;
  }
}

.table-content tr.popup:hover + tr + tr {
  // border-top-color: #444;
}
.table-content tr.popup.is-active + tr + tr {
  // border-top-color: #444;
}

.table__mobilesort {
  position: absolute;
  top: 2.9rem;
  right: 1rem;
  display: none;

  &::after {
    content: "";
    display: block;
    width: 16px;
    height: 9px;
    background-image: url("../images/arrow-down.png");
    background-size: 16px 9px;
    position: absolute;
    top: 7px;
    right: 0;
    pointer-events: none;
  }

  @include bp-s() {
    display: block;
  }

  select {
    background: transparent;
    width: 150px;
    height: auto;
    font-size: 1rem;
    line-height: 1.4rem;
    border: 0;
    border-radius: 0;
    text-align: right;
    text-align-last: right;
    padding-right: 25px;
    -webkit-appearance: none;

    &:focus {
      outline: none;
      color: #444;
    }
  }
}
