.main--project {
  // padding: 1rem 2rem;
  box-sizing: border-box;
  // padding-top: 5rem;
  // background: $c-lightgrey;

  @include bp-s() {
    // padding: 0.5rem 1rem;
    // padding-top: 3rem;
  }
}

.project {
  &__top {
    padding: 1rem 2rem;
    box-sizing: border-box;
    padding-top: 5rem;
    padding-bottom: 0;
    background: $c-lightgrey;

    @include bp-s() {
      padding: 0.5rem 1rem;
      padding-top: 3rem;
      padding-bottom: 0;
    }
  }
  &__header {
    position: fixed;
    top: 3.5rem;
    left: 0;
    width: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    z-index: 2;

    @include bp-s() {
      padding: 0 1rem;
      top: 3rem;

      h1 {
        display: none;
      }
    }
  }
  &__grid {
    padding: 1rem 2rem;
    padding-top: 3rem;

    figcaption {
      font-size: 0.75rem;
      padding: 0.2rem 0;
    }
    &__row {
      display: grid;
      column-gap: 0.5rem;
      grid-template-columns: repeat(12, 1fr);

      &--i2 {
        .project__grid__col {
          grid-column: span 6;
        }
      }
      &--i3 {
        .project__grid__col {
          grid-column: span 4;
        }
      }
      &--i4 {
        .project__grid__col {
          grid-column: span 3;
        }
      }
    }
    &__col {
      grid-column: 1 / span 12;
      padding-bottom: 8px;

      img,
      video {
        display: block;
        width: 100%;
        height: auto;
      }
      @for $i from 1 through 12 {
        &.m#{$i} {
          grid-column-start: #{$i + 1};
        }
        &.c#{$i} {
          grid-column-end: span #{$i};
        }
      }
    }
    @include bp-s() {
      padding: 1rem;
      padding-top: 1rem;

      &__col {
        grid-column: 1 / span 12 !important;
      }
    }
  }

  &__closelink {
    top: 1.3rem;
    right: 1rem;
    position: absolute;
    display: block;
    width: 25px;
    height: 25px;
    background-image: url('../images/icon-back.svg');
    background-size: 100%;

    span {
      display: none;
    }

    @include bp-s() {
      top: 0;
    }
  }
  &__title {
    position: relative;
    z-index: 2;

    &__infolink {
      display: inline-block;
      margin-bottom: 1rem;
    }
  }

  &__info {
    position: relative;
    margin-top: -2.5rem;
    background-color: $c-lightgrey;
    left: -2rem;
    width: calc(100% + 4rem);
    height: 100%;
    border-bottom: 1px solid #000;
    box-sizing: border-box;
    padding: 2rem;
    padding-top: 3rem;
    z-index: 1;
    // display: none;

    @include bp-m() {
      padding-top: 2rem;
    }
    @include bp-s() {
      padding-top: 2rem;
      left: -2rem;
      width: calc(100% + 3rem);
    }

    &__inner {
      box-sizing: border-box;
      display: flex;

      p + p {
        margin-top: 1rem;
      }

      @include bp-m() {
        flex-direction: column-reverse;
      }
    }
    &__table {
      line-height: 1.4;
      width: 50%;
      padding-right: 2rem;
      box-sizing: border-box;

      table {
        width: 100%;
        font-size: 0.75rem;

        td:first-child {
          width: 12rem;

          @include bp-m() {
            width: 10rem;
          }
        }
      }
      @include bp-m() {
        width: 100%;
        margin-top: 2rem;
      }
    }
    &__text {
      width: 50%;

      @include bp-m() {
        width: 100%;
      }
    }
    &__close {
      display: block;
      position: absolute;
      top: 0;
      width: 25px;
      height: 25px;
      left: 2rem;
      background-image: url("../images/icon-close.svg");
      background-size: 100%;

      span {
        display: none;
      }
      @include bp-m() {
        top: 0;
      }
      @include bp-s() {
        top: 0;
      }
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  z-index: 99;
  border: 2px solid #eee;
  border-top-color: #888;
  border-radius: 100%;
  animation: 1.5s infinite linear loadspin;
}

@keyframes loadspin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
