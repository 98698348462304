.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
  font-size: 1rem;
  box-sizing: border-box;
  z-index: 11;
  border-bottom: 1px solid #000;

  &--home {
    display: none;
  }

  a {
    &:hover {
      color: #000;
    }
  }

  @include bp-s() {
    padding: 0 1rem;
    height: auto;
    display: none;
    top: 0;
    line-height: 2rem;
    z-index: 3;

    &.is-visible {
      display: block;
    }
  }

  @media print {
    display: none;
  }
}
.nav-main {
  margin-left: 2rem;
  position: relative;
  height: 2.5rem;
  line-height: 2.5rem;
  display: flex;

  ul {
    display: flex;

    li {
      margin-right: 1rem;

      a {
        transition: 0.1s color;

        &.active {
          color: #000;
        }
      }
    }
  }
  &__title {
    color: $c-green;
    margin-right: 1rem;
  }

  @include bp-m() {
    ul {
      li {
        margin-right: 0.5rem;
      }
    }
  }
  @include bp-s() {
    padding: 0.3rem 0;
    margin-left: 0;
    line-height: 2rem;
    height: auto;

    ul {
      flex-direction: column;
      
      li {
        display: block;
      }
    }
    &__title {
      display: none;
    }
  }
}
.lang-switch {
  position: absolute;
  right: 2rem;
  top: 0;
  display: none;

  @include bp-s() {
    right: 0rem;
    top: auto;
    bottom: 0.5rem;
  }
}

.nav-main__subnav {
  position: fixed;
  top: 2.5rem;
  left: 0;
  width: 100%;
  height: calc(100vh - 2.5rem);
  background-color: rgba(250, 250, 250, 0.65);
  backdrop-filter: blur(10px);
  padding-left: 2.5rem;
  padding-bottom: 3rem;
  display: none;
  line-height: 1.5;
  box-sizing: border-box;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @include bp-s() {
    top: 18rem;
    height: calc(100vh - 16rem);
    padding: 0.5rem 1rem;
  }

  ul {
    margin-top: 0.5rem;

    li {
      display: block;
    }
  }
}

.mobileheader {
  position: fixed;
  top: 0;
  left: 0;
  height: 2.5rem;
  width: 100vw;
  background: #fff;
  z-index: 2;
  display: none;
  padding: 0 1rem;
  box-sizing: border-box;

  @include bp-s() {
    display: block;
  }

  span {
    line-height: 2.5rem;
    white-space: nowrap;
  }
}
.mobilenav-toggle {
  width: 2rem;
  height: 2rem;
  position: fixed;
  right: 1rem;
  top: 0.2rem;
  z-index: 3;
  display: none;
  transition: 0.4s transform cubic-bezier(0.23, 1, 0.32, 1);

  &::before {
    content: "";
    display: block;
    height: 1px;
    width: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    background-color: $c-grey;
    box-shadow: 0 6px 0 0 $c-grey, 0 -6px 0 0 $c-grey;
  }

  &.is-active {
    transform: rotate(90deg) translate(-0.2rem, -0.4rem);
  }

  @include bp-s() {
    display: block;
  }
}

.header__news {
  background-color: $c-green;
  padding: 0 2.5rem;

  @include bp-s() {
    display: none;
  }

  &__block {
    width: 11rem;
    float: left;
    padding: 0.5rem 0.5rem 0.5rem 0;
    box-sizing: border-box;
  }
  .date {
    display: block;
  }
  span {
    line-height: 1.3;
  }
}
