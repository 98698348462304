$c-grey: #a5a5a5;
$c-lightgrey: #f5f5f5;
$c-green: #66ff99;

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

@mixin bp-s() {
  @media screen and (max-width: 700px) {
    @content;
  }
}

@mixin bp-m() {
  @media screen and (max-width: 1000px) {
    @content;
  }
}

@mixin bp-l() {
  @media screen and (min-width: 1500px) {
    @content;
  }
}

.is-hidden-s {
  @include bp-s() {
    display: none;
  }
}
.is-hidden-m {
  @include bp-m() {
    display: none;
  }
}
.is-visible-s {
  @media screen and (min-width: 700px) {
    display: none;
  }
}
