@import '_reset';
@import '_helpers';
@import '_swiper';
@import '_site';
@import '_header';
@import '_home';
@import '_works';
@import '_project';
@import '_projects';
@import '_magazine';
@import '_about';
@import '_table';