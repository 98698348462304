.main--magazine {
  padding-top: 2.5rem;
  box-sizing: border-box;
  // background-color: #000;
  // color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @include bp-s() {
    padding-top: 2rem;
  }
}

.magazine {
  height: 100%;

  &__intro {
    // font-size: 3.25rem;
    line-height: 1;
    letter-spacing: -0.03rem;
    hyphens: auto;
    margin-bottom: 1rem;

    p {
      font-size: 3.5rem;

      @include bp-m() {
        font-size: 2.75rem;
      }
      @include bp-s() {
        font-size: 1.75rem;
      }
    }

    h1,
    h2,
    h3 {
      margin: 0;
      line-height: 1;
      letter-spacing: -0.03rem;
      hyphens: auto;

      &:first-child {
        margin: 0;
      }
      @include bp-s() {
        letter-spacing: 0;
      }
    }
    h1 {
      font-size: 3.5rem;

      @include bp-m() {
        font-size: 2.75rem;
      }
      @include bp-s() {
        font-size: 1.75rem;
      }
    }
    h2 {
      font-size: 2.75rem;

      @include bp-m() {
        font-size: 2rem;
      }
      @include bp-s() {
        font-size: 1.5rem;
      }
    }
    h3 {
      font-size: 2.25rem;

      @include bp-m() {
        font-size: 1.75rem;
      }
      @include bp-s() {
        font-size: 1.5rem;
      }
    }
  }
  &__pagination {
    bottom: 0.5rem !important;

    .swiper-pagination-bullet {
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 100%;
      background-color: $c-grey;
      opacity: 1;
      margin: 0 0.35rem !important;

      &-active {
        background-color: $c-green;
      }

      @include bp-s() {
        width: 0.5rem;
        height: 0.5rem;
        margin: 0 0.25rem !important;
      }
    }
  }

  &__fulltext {
    display: table;
    height: 100%;
    width: 100%;

    &__inner {
      display: table-cell;
      height: 100%;
      vertical-align: middle;
      text-align: center;
      padding: 2rem 8rem;
      font-size: 2rem;
      // color: $c-grey;

      @include bp-s() {
        padding: 1rem;
        font-size: 1.5rem;
      }
    }
  }

  &__fullimage {
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &__caption {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      padding-bottom: 2.5rem;
      background-color: #000;
      color: $c-grey;
      font-size: 0.9rem;
      text-align: center;
      padding-top: 0.3rem;

      p {
        max-width: 600px;
        margin: 0 auto;
      }
    }
  }

  &__content {
    max-width: 46rem;
  }
  &__split {
    overflow: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;

    &__inner {
      display: flex;
    }

    &__left {
      width: 50%;
      min-height: calc(100vh - 45px);
      background-color: #fff;
      position: relative;
      background-color: $c-lightgrey;

      @include bp-s() {
        display: none;
      }

      .fixedleft & {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
      }
    }
    &__right {
      width: 50%;
      padding: 2.5rem;
      box-sizing: border-box;
      // color: $c-grey;
      position: relative;

      .fixedleft & {
        left: 50%;

        @include bp-s() {
          left: 0;
        }
      }

      p {
        margin-bottom: 1rem;
        max-width: 46rem;
      }
      h1 {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
      }
      a {
        // color: #fff;
      }
      figure {
        display: none;
      }
      img {
        display: none;
      }

      @include bp-s() {
        width: 100%;
        padding: 1rem;
        margin-bottom: 2rem;

        figure {
          display: block;
          width: 100%;
          margin: 1rem auto;

          img {
            display: block;
            max-width: 70%;
            margin: 0 auto;
            box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.4);
          }
          figcaption {
            font-size: 0.8rem;
            width: 100%;
            text-align: center;
            margin: 0.5rem 0;
          }
        }
      }
    }
    &__fullimage {
      background-position: center;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      height: calc(100vh - 2.5rem);
      width: 100%;

      &__caption {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        padding-bottom: 2.5rem;
        background-color: #000;
        color: $c-grey;
        font-size: 0.9rem;
        text-align: center;
        padding-top: 0.3rem;

        p {
          max-width: 600px;
          margin: 0 auto;
        }
      }
    }
    &__images {
      text-align: center;
      padding-top: 0.6rem;

      figure {
        display: block;
        width: 100%;
        margin: 1.5rem auto;

        img {
          width: auto;
          height: auto;
          max-width: 90%;
          max-height: 28rem;
        }
        figcaption {
          color: #000;
          font-size: 0.8rem;
        }
      }
    }
  }
}
