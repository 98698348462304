.main--works {
  background-color: $c-lightgrey;
  min-height: calc(100vh - 2.56rem);
  margin-top: 2.5rem;
  // border-top: 1px solid #000;
  padding-bottom: 6rem;
  overflow: hidden;
  // overflow-x: scroll;

  @media print {
    background-color: #fff;
  }

  &__filter {
    background: #fff;
    padding: 1rem 2rem;
    border-bottom: 1px solid #ccc;
  }

  @include bp-s() {
    margin-top: 2rem;
    border-top: none;

    &__filter {
      padding: 1rem;
    }
  }
}

.works__table {
  width: 100%;
  table-layout: fixed;
  letter-spacing: 0;
  // overflow: hidden;

  tr {
    position: relative;
    color: $c-grey;
    border-top: 1px solid #ccc;
    transition: 0.2s background-color, 0.2s color, 0.2s border-color;

    &.inactive {
      background-color: #fefefe;

      @media print {
        background-color: #fff;
      }
    }

    &.popup {
      cursor: pointer;

      &:hover {
        background-color: $c-green;
        color: #000;
        border-color: #444;

        .work-preview {
          // opacity: 1;
          // transition: .3s opacity;
        }
      }
    }
    &.head {
      border-top: none;
    }

    &:last-child {
      border-bottom: 1px solid #ccc;
    }

    @media print {
      color: black;
    }
  }
  th {
    color: $c-grey;
    padding: 0.5rem 0.5rem 2rem 0;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-child {
      padding-left: 2rem;
    }
    &:last-child {
      padding-right: 2rem;
    }

    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      width: 10%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 18%;
    }
    &:nth-child(5) {
      width: 9%;
    }
    &:nth-child(6) {
      width: 9%;
    }
    &:nth-child(7) {
      width: 7%;
    }
    &:nth-child(8) {
      width: 7%;
    }

    a {
      color: inherit;

      &:hover {
        color: #777;
      }

      @media print {
        color: #000;
      }
    }
    &.right {
      text-align: right;
    }
    &.active {
      color: #000;
    }

    @include bp-s() {
      padding-top: 2rem;

      &:first-child {
        padding-left: 1rem;
      }
      &:last-child {
        padding-right: 1rem;
      }
    }
    @media print {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }

      &:nth-child(6) {
        width: 11%;
      }
      &:nth-child(8) {
        width: 5%;
      }
    }
  }
  td {
    color: inherit;
    padding: 0.3rem 0.5rem 0.8rem 0;
    text-align: left;
    font-size: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include bp-l() {
      font-size: 1rem;
    }

    &:first-child {
      padding-left: 2rem;
    }
    &:last-child {
      padding-right: 2rem;
    }

    &.right {
      text-align: right;
    }
    a {
      color: inherit;

      &:hover {
        color: inherit;
      }
    }

    @include bp-s() {
      &:first-child {
        padding-left: 1rem;
      }
      &:last-child {
        padding-right: 1rem;
      }
    }

    @media print {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.works__table tr.popup:hover + tr {
  border-top-color: #444;
}

.works__mobilesort {
  position: absolute;
  top: 2.9rem;
  right: 1rem;
  display: none;

  &::after {
    content: "";
    display: block;
    width: 16px;
    height: 9px;
    background-image: url("../images/arrow-down.png");
    background-size: 16px 9px;
    position: absolute;
    top: 7px;
    right: 0;
    pointer-events: none;
  }

  @include bp-s() {
    display: block;
  }

  select {
    background: transparent;
    width: 150px;
    height: auto;
    font-size: 1rem;
    line-height: 1.4rem;
    border: 0;
    border-radius: 0;
    text-align: right;
    text-align-last: right;
    padding-right: 25px;
    -webkit-appearance: none;

    &:focus {
      outline: none;
      color: #444;
    }
  }
}

.work-preview {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 10;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 0;
  width: 30vw;
  height: auto;
  transition: .5s opacity;
  background-color: #fff;
  display: none;
}
