.main--projects {
  margin-top: 2.5rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
  background-color: $c-lightgrey;
  min-height: calc(100vh - 2.5rem);

  @include bp-s() {
    padding: 1rem;
  }
}

.projects {
  margin-top: 1rem;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 25vw;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    margin-right: 4px;
    margin-bottom: 5rem;
    filter: grayscale(100%);
    grid-column: span 4;
    transition: 0.5s filter;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;

      &.portrait {
        object-fit: contain;
        object-position: 0%;
      }
    }
    &__title {
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      color: #000;
      line-height: 1.2;
      padding-top: 0.5rem;
    }

    &:hover {
      filter: grayscale(0%);
    }

    &__bg {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center;
    }
  }
  @include bp-m() {
    grid-auto-rows: 35vw;

    &__item {
      grid-column: span 6;
    }
  }
  @include bp-s() {
    grid-auto-rows: 80vw;

    &__item {
      grid-column: span 12;
    }
  }
}

.project-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: scroll;
}

.projectfilter {
  color: $c-grey;
  padding-top: 0.5rem;
  line-height: 1.4;
  position: fixed;
  top: 2.5rem;
  left: 0;
  width: 100%;
  height: calc(100vh - 2.5rem);
  background-color: rgba(250, 250, 250, 0.85);
  backdrop-filter: blur(10px);
  padding-left: 2.5rem;
  padding-bottom: 3rem;
  line-height: 1.5;
  box-sizing: border-box;
  z-index: 2;
  display: none;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @include bp-s() {
    padding: 0.5rem 1rem;
  }

  ul {
    margin-top: 0.5rem;

    li {
      display: block;
    }
  }

  h3 {
    line-height: 1.4;
  }
  button {
    -webkit-appearance: none;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1rem;
    color: $c-grey;
    cursor: pointer;

    &:hover {
      color: #000;
    }
  }
  ul {
    margin-top: 0;
    margin-bottom: 1.5rem;

    li {
      display: block;
      line-height: 1.3;

      label {
        position: relative;
        padding-left: 1rem;
        cursor: pointer;

        &::before {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: $c-grey;
          position: absolute;
          top: 0.27rem;
          left: 0;

          @include bp-m() {
            width: 8px;
            height: 8px;
            top: 5px;
          }
        }
        &:hover {
          color: #777;

          &::before {
            background-color: #777;
          }
        }
      }
      input[type="checkbox"] {
        display: none;
      }
      input[type="checkbox"]:checked + label {
        color: #000;
      }
      input[type="checkbox"]:checked + label::before {
        background-color: $c-green;
      }
    }
  }
}
