html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

@media screen and (max-width: 700px) {
  .is-hidden-s {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .is-hidden-m {
    display: none;
  }
}

@media screen and (min-width: 700px) {
  .is-visible-s {
    display: none;
  }
}

.swiper-container {
  z-index: 1;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  z-index: 1;
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: -o-transform;
  -moz-transition-property: -moz-transform;
  -ms-transition-property: -ms-transform;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  transition-property: transform;
  display: flex;
  position: relative;
}

.swiper-container-android .swiper-slide, .swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate(0);
  transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -o-transition-timing-function: ease-out;
  margin: 0 auto;
  transition-timing-function: ease-out;
}

.swiper-slide {
  -ms-flex: none;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-transition-property: -webkit-transform, height;
  -o-transition-property: -o-transform;
  -moz-transition-property: -moz-transform;
  -ms-transition-property: -ms-transform;
  align-items: flex-start;
  transition-property: transform, height;
}

.swiper-container .swiper-notification {
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
  position: absolute;
  top: 0;
  left: 0;
}

.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.swiper-button-prev, .swiper-button-next {
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 27px 44px;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  position: absolute;
  top: 50%;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: auto;
  right: 10px;
}

.swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-pagination {
  text-align: center;
  -o-transition: .3s;
  z-index: 10;
  transition: all .3s;
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  width: 100%;
  bottom: 10px;
  left: 0;
}

.swiper-pagination-bullet {
  opacity: .2;
  background: #000;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
}

button.swiper-pagination-bullet {
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -o-transform: translate(0, -50%);
  top: 50%;
  right: 10px;
  transform: translate3d(0, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
}

.swiper-pagination-progress {
  background: #00000040;
  position: absolute;
}

.swiper-pagination-progress .swiper-pagination-progressbar {
  transform-origin: 0 0;
  background: #007aff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  transform-origin: 100% 0;
}

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  top: 0;
  left: 0;
}

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  top: 0;
  left: 0;
}

.swiper-pagination-progress.swiper-pagination-white {
  background: #ffffff80;
}

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff;
}

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000;
}

.swiper-container-3d {
  perspective: 1200px;
  -o-perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow {
  transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom {
  pointer-events: none;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, #00000080, #0000);
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, #00000080, #0000);
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(to top, #00000080, #0000);
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(#00000080, #0000);
}

.swiper-container-coverflow .swiper-wrapper, .swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px;
}

.swiper-container-cube, .swiper-container-flip {
  overflow: visible;
}

.swiper-container-cube .swiper-slide, .swiper-container-flip .swiper-slide {
  pointer-events: none;
  backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide, .swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube .swiper-slide-active, .swiper-container-flip .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-top, .swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right, .swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}

.swiper-container-cube .swiper-slide {
  visibility: hidden;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-prev, .swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-cube-shadow {
  opacity: .6;
  filter: blur(50px);
  z-index: 0;
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -o-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-scrollbar {
  -ms-touch-action: none;
  background: #0000001a;
  border-radius: 10px;
  position: relative;
}

.swiper-container-horizontal > .swiper-scrollbar {
  z-index: 50;
  width: 98%;
  height: 5px;
  position: absolute;
  bottom: 3px;
  left: 1%;
}

.swiper-container-vertical > .swiper-scrollbar {
  z-index: 50;
  width: 5px;
  height: 98%;
  position: absolute;
  top: 1%;
  right: 3px;
}

.swiper-scrollbar-drag {
  background: #00000080;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-lazy-preloader {
  z-index: 10;
  transform-origin: 50%;
  width: 42px;
  height: 42px;
  margin-top: -21px;
  margin-left: -21px;
  animation: 1s steps(12, end) infinite swiper-preloader-spin;
  position: absolute;
  top: 50%;
  left: 50%;
}

.swiper-lazy-preloader:after {
  content: "";
  -webkit-background-size: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
  display: block;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

body, html {
  min-height: 100%;
}

html {
  font-size: 18px;
}

@media screen and (max-width: 1000px) {
  html {
    font-size: 15px;
  }
}

@media print {
  html {
    font-size: 12px;
  }
}

body {
  letter-spacing: .02em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 1.3;
}

a {
  color: #a5a5a5;
  text-decoration: none;
}

a:hover {
  color: #000;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.main {
  transition: -webkit-filter .3s, filter .3s;
}

.main--default, .main--contact {
  box-sizing: border-box;
  background-color: #f5f5f5;
  min-height: calc(100vh - 2.56rem);
  margin-top: 2.5rem;
  padding: 1rem 2rem 3rem;
  position: relative;
}

@media screen and (max-width: 700px) {
  .main--default, .main--contact {
    min-height: calc(100vh - 2rem);
    margin-top: 30px;
    padding: 1rem 1rem 2rem;
  }
}

.main--default p + p, .main--contact p + p {
  margin-top: 1rem;
}

.main--default p, .main--contact p {
  max-width: 38rem;
}

.main--default p + h1, .main--contact p + h1 {
  margin-top: 2rem;
}

.br-spacer {
  width: 2rem;
  display: inline-block;
}

.comma-list, .comma-list li {
  display: inline;
}

.comma-list li:after {
  content: ", ";
}

.comma-list li:last-child:after {
  content: "";
}

.section-headline {
  color: #a5a5a5;
  background-color: #fff;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 2rem;
}

@media screen and (max-width: 700px) {
  .section-headline {
    padding: 1.5rem 1rem;
  }
}

.videoplayer {
  position: relative;
}

.videoplayer__mute {
  -webkit-appearance: none;
  appearance: none;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  cursor: pointer;
  z-index: 10;
  background-color: #ffffff4d;
  background-image: url("../icon-mute.93e75ac5.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  border: none;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  transition: background-color .2s;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.videoplayer__mute:hover {
  background-color: #e6e6e6b3;
}

.videoplayer__mute span {
  display: none;
}

.videoplayer__mute.active {
  background-image: url("../icon-mute-active.33be3247.svg");
}

.header {
  box-sizing: border-box;
  z-index: 11;
  background-color: #fff;
  border-bottom: 1px solid #000;
  width: 100%;
  height: auto;
  font-size: 1rem;
  position: fixed;
  top: 0;
  left: 0;
}

.header--home {
  display: none;
}

.header a:hover {
  color: #000;
}

@media screen and (max-width: 700px) {
  .header {
    z-index: 3;
    height: auto;
    padding: 0 1rem;
    line-height: 2rem;
    display: none;
    top: 0;
  }

  .header.is-visible {
    display: block;
  }
}

@media print {
  .header {
    display: none;
  }
}

.nav-main {
  height: 2.5rem;
  margin-left: 2rem;
  line-height: 2.5rem;
  display: flex;
  position: relative;
}

.nav-main ul {
  display: flex;
}

.nav-main ul li {
  margin-right: 1rem;
}

.nav-main ul li a {
  transition: color .1s;
}

.nav-main ul li a.active {
  color: #000;
}

.nav-main__title {
  color: #6f9;
  margin-right: 1rem;
}

@media screen and (max-width: 1000px) {
  .nav-main ul li {
    margin-right: .5rem;
  }
}

@media screen and (max-width: 700px) {
  .nav-main {
    height: auto;
    margin-left: 0;
    padding: .3rem 0;
    line-height: 2rem;
  }

  .nav-main ul {
    flex-direction: column;
  }

  .nav-main ul li {
    display: block;
  }

  .nav-main__title {
    display: none;
  }
}

.lang-switch {
  display: none;
  position: absolute;
  top: 0;
  right: 2rem;
}

@media screen and (max-width: 700px) {
  .lang-switch {
    top: auto;
    bottom: .5rem;
    right: 0;
  }
}

.nav-main__subnav {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  background-color: #fafafaa6;
  width: 100%;
  height: calc(100vh - 2.5rem);
  padding-bottom: 3rem;
  padding-left: 2.5rem;
  line-height: 1.5;
  display: none;
  position: fixed;
  top: 2.5rem;
  left: 0;
  overflow-y: scroll;
}

@media screen and (max-width: 700px) {
  .nav-main__subnav {
    height: calc(100vh - 16rem);
    padding: .5rem 1rem;
    top: 18rem;
  }
}

.nav-main__subnav ul {
  margin-top: .5rem;
}

.nav-main__subnav ul li {
  display: block;
}

.mobileheader {
  z-index: 2;
  box-sizing: border-box;
  background: #fff;
  width: 100vw;
  height: 2.5rem;
  padding: 0 1rem;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 700px) {
  .mobileheader {
    display: block;
  }
}

.mobileheader span {
  white-space: nowrap;
  line-height: 2.5rem;
}

.mobilenav-toggle {
  z-index: 3;
  width: 2rem;
  height: 2rem;
  transition: transform .4s cubic-bezier(.23, 1, .32, 1);
  display: none;
  position: fixed;
  top: .2rem;
  right: 1rem;
}

.mobilenav-toggle:before {
  content: "";
  background-color: #a5a5a5;
  width: 20px;
  height: 1px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  box-shadow: 0 6px #a5a5a5, 0 -6px #a5a5a5;
}

.mobilenav-toggle.is-active {
  transform: rotate(90deg)translate(-.2rem, -.4rem);
}

@media screen and (max-width: 700px) {
  .mobilenav-toggle {
    display: block;
  }
}

.header__news {
  background-color: #6f9;
  padding: 0 2.5rem;
}

@media screen and (max-width: 700px) {
  .header__news {
    display: none;
  }
}

.header__news__block {
  float: left;
  box-sizing: border-box;
  width: 11rem;
  padding: .5rem .5rem .5rem 0;
}

.header__news .date {
  display: block;
}

.header__news span {
  line-height: 1.3;
}

.main--home {
  box-sizing: border-box;
  margin-top: 2.5rem;
}

.home__logo {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  z-index: 10;
  background-color: #66ff99b3;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 1px;
  left: 0;
}

.home__logo img {
  width: 40%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1000px) {
  .home__logo img {
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .home__logo img {
    width: 80%;
  }
}

.home__grid {
  box-sizing: border-box;
  z-index: 0;
  background: #f5f5f5;
  grid-template-columns: repeat(4, 1fr);
  gap: 4px;
  padding: .5rem;
  display: grid;
  position: relative;
}

.home__grid__block {
  box-sizing: border-box;
  height: 35vw;
  position: relative;
}

.home__grid__block--c1 {
  grid-column: span 1;
  font-size: 1vw;
}

.home__grid__block--c2 {
  grid-column: span 2;
  font-size: 2vw;
}

.home__grid__block--c3 {
  grid-column: span 3;
  font-size: 3vw;
}

.home__grid__block--c4 {
  grid-column: span 4;
  font-size: 4vw;
}

.home__grid__block--sm {
  font-size: 1vw;
}

.home__grid__block--md {
  font-size: 3vw;
}

.home__grid__block--lg {
  font-size: 4vw;
}

.home__grid__wrap {
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1vw;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.home__grid__wrap:before {
  content: "";
  mix-blend-mode: multiply;
  z-index: 1;
  opacity: 0;
  background-color: #6f9;
  width: 100%;
  height: 100%;
  transition: opacity .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.home__grid__text {
  font-size: inherit;
  color: #000;
}

.home__grid__text--overlay {
  z-index: 1;
  color: #fff;
  box-sizing: border-box;
  text-align: left;
  opacity: 0;
  width: 100%;
  padding: .5rem;
  font-size: 1vw;
  transition: opacity .5s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.home__grid__image {
  object-fit: cover;
  filter: grayscale();
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 700px) {
  .home__grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .home__grid__text--overlay {
    font-size: 3vw;
  }

  .home__grid__block {
    height: 50vw;
  }

  .home__grid__block--c1 {
    grid-column: span 2;
    font-size: 3vw;
  }

  .home__grid__block--c2 {
    font-size: 3vw;
  }

  .home__grid__block--c3 {
    grid-column: span 4;
    font-size: 4vw;
  }

  .home__grid__block--c4 {
    font-size: 6vw;
  }

  .home__grid__block--sm {
    font-size: 3vw;
  }

  .home__grid__block--md {
    font-size: 4vw;
  }

  .home__grid__block--lg {
    font-size: 6vw;
  }
}

a.home__grid__wrap:hover:before, a.home__grid__wrap:hover .home__grid__text--overlay {
  opacity: 1;
  transition: opacity .2s;
}

.main--works {
  background-color: #f5f5f5;
  min-height: calc(100vh - 2.56rem);
  margin-top: 2.5rem;
  padding-bottom: 6rem;
  overflow: hidden;
}

@media print {
  .main--works {
    background-color: #fff;
  }
}

.main--works__filter {
  background: #fff;
  border-bottom: 1px solid #ccc;
  padding: 1rem 2rem;
}

@media screen and (max-width: 700px) {
  .main--works {
    border-top: none;
    margin-top: 2rem;
  }

  .main--works__filter {
    padding: 1rem;
  }
}

.works__table {
  table-layout: fixed;
  letter-spacing: 0;
  width: 100%;
}

.works__table tr {
  color: #a5a5a5;
  border-top: 1px solid #ccc;
  transition: background-color .2s, color .2s, border-color .2s;
  position: relative;
}

.works__table tr.inactive {
  background-color: #fefefe;
}

@media print {
  .works__table tr.inactive {
    background-color: #fff;
  }
}

.works__table tr.popup {
  cursor: pointer;
}

.works__table tr.popup:hover {
  color: #000;
  background-color: #6f9;
  border-color: #444;
}

.works__table tr.head {
  border-top: none;
}

.works__table tr:last-child {
  border-bottom: 1px solid #ccc;
}

@media print {
  .works__table tr {
    color: #000;
  }
}

.works__table th {
  color: #a5a5a5;
  text-align: left;
  text-overflow: ellipsis;
  padding: .5rem .5rem 2rem 0;
  overflow: hidden;
}

.works__table th:first-child {
  padding-left: 2rem;
}

.works__table th:last-child {
  padding-right: 2rem;
}

.works__table th:first-child {
  width: 20%;
}

.works__table th:nth-child(2) {
  width: 10%;
}

.works__table th:nth-child(3) {
  width: 20%;
}

.works__table th:nth-child(4) {
  width: 18%;
}

.works__table th:nth-child(5), .works__table th:nth-child(6) {
  width: 9%;
}

.works__table th:nth-child(7), .works__table th:nth-child(8) {
  width: 7%;
}

.works__table th a {
  color: inherit;
}

.works__table th a:hover {
  color: #777;
}

@media print {
  .works__table th a {
    color: #000;
  }
}

.works__table th.right {
  text-align: right;
}

.works__table th.active {
  color: #000;
}

@media screen and (max-width: 700px) {
  .works__table th {
    padding-top: 2rem;
  }

  .works__table th:first-child {
    padding-left: 1rem;
  }

  .works__table th:last-child {
    padding-right: 1rem;
  }
}

@media print {
  .works__table th:first-child {
    padding-left: 0;
  }

  .works__table th:last-child {
    padding-right: 0;
  }

  .works__table th:nth-child(6) {
    width: 11%;
  }

  .works__table th:nth-child(8) {
    width: 5%;
  }
}

.works__table td {
  color: inherit;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: .3rem .5rem .8rem 0;
  font-size: .75rem;
  overflow: hidden;
}

@media screen and (min-width: 1500px) {
  .works__table td {
    font-size: 1rem;
  }
}

.works__table td:first-child {
  padding-left: 2rem;
}

.works__table td:last-child {
  padding-right: 2rem;
}

.works__table td.right {
  text-align: right;
}

.works__table td a, .works__table td a:hover {
  color: inherit;
}

@media screen and (max-width: 700px) {
  .works__table td:first-child {
    padding-left: 1rem;
  }

  .works__table td:last-child {
    padding-right: 1rem;
  }
}

@media print {
  .works__table td:first-child {
    padding-left: 0;
  }

  .works__table td:last-child {
    padding-right: 0;
  }
}

.works__table tr.popup:hover + tr {
  border-top-color: #444;
}

.works__mobilesort {
  display: none;
  position: absolute;
  top: 2.9rem;
  right: 1rem;
}

.works__mobilesort:after {
  content: "";
  pointer-events: none;
  background-image: url("../arrow-down.e5cadc9d.png");
  background-size: 16px 9px;
  width: 16px;
  height: 9px;
  display: block;
  position: absolute;
  top: 7px;
  right: 0;
}

@media screen and (max-width: 700px) {
  .works__mobilesort {
    display: block;
  }
}

.works__mobilesort select {
  text-align: right;
  text-align-last: right;
  -webkit-appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  width: 150px;
  height: auto;
  padding-right: 25px;
  font-size: 1rem;
  line-height: 1.4rem;
}

.works__mobilesort select:focus {
  color: #444;
  outline: none;
}

.work-preview {
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  background-color: #fff;
  width: 30vw;
  height: auto;
  transition: opacity .5s;
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main--project {
  box-sizing: border-box;
}

.project__top {
  box-sizing: border-box;
  background: #f5f5f5;
  padding: 5rem 2rem 0;
}

@media screen and (max-width: 700px) {
  .project__top {
    padding: 3rem 1rem 0;
  }
}

.project__header {
  box-sizing: border-box;
  z-index: 2;
  justify-content: space-between;
  width: 100%;
  padding: 0 2rem;
  display: flex;
  position: fixed;
  top: 3.5rem;
  left: 0;
}

@media screen and (max-width: 700px) {
  .project__header {
    padding: 0 1rem;
    top: 3rem;
  }

  .project__header h1 {
    display: none;
  }
}

.project__grid {
  padding: 3rem 2rem 1rem;
}

.project__grid figcaption {
  padding: .2rem 0;
  font-size: .75rem;
}

.project__grid__row {
  grid-template-columns: repeat(12, 1fr);
  column-gap: .5rem;
  display: grid;
}

.project__grid__row--i2 .project__grid__col {
  grid-column: span 6;
}

.project__grid__row--i3 .project__grid__col {
  grid-column: span 4;
}

.project__grid__row--i4 .project__grid__col {
  grid-column: span 3;
}

.project__grid__col {
  grid-column: 1 / span 12;
  padding-bottom: 8px;
}

.project__grid__col img, .project__grid__col video {
  width: 100%;
  height: auto;
  display: block;
}

.project__grid__col.m1 {
  grid-column-start: 2;
}

.project__grid__col.c1 {
  grid-column-end: span 1;
}

.project__grid__col.m2 {
  grid-column-start: 3;
}

.project__grid__col.c2 {
  grid-column-end: span 2;
}

.project__grid__col.m3 {
  grid-column-start: 4;
}

.project__grid__col.c3 {
  grid-column-end: span 3;
}

.project__grid__col.m4 {
  grid-column-start: 5;
}

.project__grid__col.c4 {
  grid-column-end: span 4;
}

.project__grid__col.m5 {
  grid-column-start: 6;
}

.project__grid__col.c5 {
  grid-column-end: span 5;
}

.project__grid__col.m6 {
  grid-column-start: 7;
}

.project__grid__col.c6 {
  grid-column-end: span 6;
}

.project__grid__col.m7 {
  grid-column-start: 8;
}

.project__grid__col.c7 {
  grid-column-end: span 7;
}

.project__grid__col.m8 {
  grid-column-start: 9;
}

.project__grid__col.c8 {
  grid-column-end: span 8;
}

.project__grid__col.m9 {
  grid-column-start: 10;
}

.project__grid__col.c9 {
  grid-column-end: span 9;
}

.project__grid__col.m10 {
  grid-column-start: 11;
}

.project__grid__col.c10 {
  grid-column-end: span 10;
}

.project__grid__col.m11 {
  grid-column-start: 12;
}

.project__grid__col.c11 {
  grid-column-end: span 11;
}

.project__grid__col.m12 {
  grid-column-start: 13;
}

.project__grid__col.c12 {
  grid-column-end: span 12;
}

@media screen and (max-width: 700px) {
  .project__grid {
    padding: 1rem;
  }

  .project__grid__col {
    grid-column: 1 / span 12 !important;
  }
}

.project__closelink {
  background-image: url("../icon-back.c8996b0f.svg");
  background-size: 100%;
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  top: 1.3rem;
  right: 1rem;
}

.project__closelink span {
  display: none;
}

@media screen and (max-width: 700px) {
  .project__closelink {
    top: 0;
  }
}

.project__title {
  z-index: 2;
  position: relative;
}

.project__title__infolink {
  margin-bottom: 1rem;
  display: inline-block;
}

.project__info {
  box-sizing: border-box;
  z-index: 1;
  background-color: #f5f5f5;
  border-bottom: 1px solid #000;
  width: calc(100% + 4rem);
  height: 100%;
  margin-top: -2.5rem;
  padding: 3rem 2rem 2rem;
  position: relative;
  left: -2rem;
}

@media screen and (max-width: 1000px) {
  .project__info {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .project__info {
    width: calc(100% + 3rem);
    padding-top: 2rem;
    left: -2rem;
  }
}

.project__info__inner {
  box-sizing: border-box;
  display: flex;
}

.project__info__inner p + p {
  margin-top: 1rem;
}

@media screen and (max-width: 1000px) {
  .project__info__inner {
    flex-direction: column-reverse;
  }
}

.project__info__table {
  box-sizing: border-box;
  width: 50%;
  padding-right: 2rem;
  line-height: 1.4;
}

.project__info__table table {
  width: 100%;
  font-size: .75rem;
}

.project__info__table table td:first-child {
  width: 12rem;
}

@media screen and (max-width: 1000px) {
  .project__info__table table td:first-child {
    width: 10rem;
  }

  .project__info__table {
    width: 100%;
    margin-top: 2rem;
  }
}

.project__info__text {
  width: 50%;
}

@media screen and (max-width: 1000px) {
  .project__info__text {
    width: 100%;
  }
}

.project__info__close {
  background-image: url("../icon-close.6e334edc.svg");
  background-size: 100%;
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  top: 0;
  left: 2rem;
}

.project__info__close span {
  display: none;
}

@media screen and (max-width: 1000px) {
  .project__info__close {
    top: 0;
  }
}

@media screen and (max-width: 700px) {
  .project__info__close {
    top: 0;
  }
}

.loader {
  z-index: 99;
  border: 2px solid #eee;
  border-top-color: #888;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  animation: 1.5s linear infinite loadspin;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes loadspin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.main--projects {
  box-sizing: border-box;
  background-color: #f5f5f5;
  min-height: calc(100vh - 2.5rem);
  margin-top: 2.5rem;
  padding: 1rem 2rem;
}

@media screen and (max-width: 700px) {
  .main--projects {
    padding: 1rem;
  }
}

.projects {
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 25vw;
  margin-top: 1rem;
  display: grid;
  position: relative;
}

.projects__item {
  filter: grayscale();
  flex-direction: column;
  grid-column: span 4;
  justify-content: flex-end;
  margin-bottom: 5rem;
  margin-right: 4px;
  transition: filter .5s;
  display: flex;
  position: relative;
}

.projects__item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.projects__item img.portrait {
  object-fit: contain;
  object-position: 0%;
}

.projects__item__title {
  color: #000;
  width: 100%;
  padding-top: .5rem;
  line-height: 1.2;
  position: absolute;
  top: 100%;
  left: 0;
}

.projects__item:hover {
  filter: grayscale(0%);
}

.projects__item__bg {
  background-position: center;
  background-size: cover;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1000px) {
  .projects {
    grid-auto-rows: 35vw;
  }

  .projects__item {
    grid-column: span 6;
  }
}

@media screen and (max-width: 700px) {
  .projects {
    grid-auto-rows: 80vw;
  }

  .projects__item {
    grid-column: span 12;
  }
}

.project-overlay {
  z-index: 10;
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.projectfilter {
  color: #a5a5a5;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  z-index: 2;
  -webkit-overflow-scrolling: touch;
  background-color: #fafafad9;
  width: 100%;
  height: calc(100vh - 2.5rem);
  padding-top: .5rem;
  padding-bottom: 3rem;
  padding-left: 2.5rem;
  line-height: 1.5;
  display: none;
  position: fixed;
  top: 2.5rem;
  left: 0;
  overflow-y: scroll;
}

@media screen and (max-width: 700px) {
  .projectfilter {
    padding: .5rem 1rem;
  }
}

.projectfilter ul {
  margin-top: .5rem;
}

.projectfilter ul li {
  display: block;
}

.projectfilter h3 {
  line-height: 1.4;
}

.projectfilter button {
  -webkit-appearance: none;
  color: #a5a5a5;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1rem;
}

.projectfilter button:hover {
  color: #000;
}

.projectfilter ul {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.projectfilter ul li {
  line-height: 1.3;
  display: block;
}

.projectfilter ul li label {
  cursor: pointer;
  padding-left: 1rem;
  position: relative;
}

.projectfilter ul li label:before {
  content: "";
  background-color: #a5a5a5;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: .27rem;
  left: 0;
}

@media screen and (max-width: 1000px) {
  .projectfilter ul li label:before {
    width: 8px;
    height: 8px;
    top: 5px;
  }
}

.projectfilter ul li label:hover {
  color: #777;
}

.projectfilter ul li label:hover:before {
  background-color: #777;
}

.projectfilter ul li input[type="checkbox"] {
  display: none;
}

.projectfilter ul li input[type="checkbox"]:checked + label {
  color: #000;
}

.projectfilter ul li input[type="checkbox"]:checked + label:before {
  background-color: #6f9;
}

.main--magazine {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 2.5rem;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 700px) {
  .main--magazine {
    padding-top: 2rem;
  }
}

.magazine {
  height: 100%;
}

.magazine__intro {
  letter-spacing: -.03rem;
  -webkit-hyphens: auto;
  hyphens: auto;
  margin-bottom: 1rem;
  line-height: 1;
}

.magazine__intro p {
  font-size: 3.5rem;
}

@media screen and (max-width: 1000px) {
  .magazine__intro p {
    font-size: 2.75rem;
  }
}

@media screen and (max-width: 700px) {
  .magazine__intro p {
    font-size: 1.75rem;
  }
}

.magazine__intro h1, .magazine__intro h2, .magazine__intro h3 {
  letter-spacing: -.03rem;
  -webkit-hyphens: auto;
  hyphens: auto;
  margin: 0;
  line-height: 1;
}

.magazine__intro h1:first-child, .magazine__intro h2:first-child, .magazine__intro h3:first-child {
  margin: 0;
}

@media screen and (max-width: 700px) {
  .magazine__intro h1, .magazine__intro h2, .magazine__intro h3 {
    letter-spacing: 0;
  }
}

.magazine__intro h1 {
  font-size: 3.5rem;
}

@media screen and (max-width: 1000px) {
  .magazine__intro h1 {
    font-size: 2.75rem;
  }
}

@media screen and (max-width: 700px) {
  .magazine__intro h1 {
    font-size: 1.75rem;
  }
}

.magazine__intro h2 {
  font-size: 2.75rem;
}

@media screen and (max-width: 1000px) {
  .magazine__intro h2 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .magazine__intro h2 {
    font-size: 1.5rem;
  }
}

.magazine__intro h3 {
  font-size: 2.25rem;
}

@media screen and (max-width: 1000px) {
  .magazine__intro h3 {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 700px) {
  .magazine__intro h3 {
    font-size: 1.5rem;
  }
}

.magazine__pagination {
  bottom: .5rem !important;
}

.magazine__pagination .swiper-pagination-bullet {
  opacity: 1;
  background-color: #a5a5a5;
  border-radius: 100%;
  width: .6rem;
  height: .6rem;
  margin: 0 .35rem !important;
}

.magazine__pagination .swiper-pagination-bullet-active {
  background-color: #6f9;
}

@media screen and (max-width: 700px) {
  .magazine__pagination .swiper-pagination-bullet {
    width: .5rem;
    height: .5rem;
    margin: 0 .25rem !important;
  }
}

.magazine__fulltext {
  width: 100%;
  height: 100%;
  display: table;
}

.magazine__fulltext__inner {
  vertical-align: middle;
  text-align: center;
  height: 100%;
  padding: 2rem 8rem;
  font-size: 2rem;
  display: table-cell;
}

@media screen and (max-width: 700px) {
  .magazine__fulltext__inner {
    padding: 1rem;
    font-size: 1.5rem;
  }
}

.magazine__fullimage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.magazine__fullimage__caption {
  color: #a5a5a5;
  text-align: center;
  background-color: #000;
  width: 100%;
  height: auto;
  padding-top: .3rem;
  padding-bottom: 2.5rem;
  font-size: .9rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.magazine__fullimage__caption p {
  max-width: 600px;
  margin: 0 auto;
}

.magazine__content {
  max-width: 46rem;
}

.magazine__split {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.magazine__split__inner {
  display: flex;
}

.magazine__split__left {
  background-color: #f5f5f5;
  width: 50%;
  min-height: calc(100vh - 45px);
  position: relative;
}

@media screen and (max-width: 700px) {
  .magazine__split__left {
    display: none;
  }
}

.fixedleft .magazine__split__left {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.magazine__split__right {
  box-sizing: border-box;
  width: 50%;
  padding: 2.5rem;
  position: relative;
}

.fixedleft .magazine__split__right {
  left: 50%;
}

@media screen and (max-width: 700px) {
  .fixedleft .magazine__split__right {
    left: 0;
  }
}

.magazine__split__right p {
  max-width: 46rem;
  margin-bottom: 1rem;
}

.magazine__split__right h1 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.magazine__split__right figure, .magazine__split__right img {
  display: none;
}

@media screen and (max-width: 700px) {
  .magazine__split__right {
    width: 100%;
    margin-bottom: 2rem;
    padding: 1rem;
  }

  .magazine__split__right figure {
    width: 100%;
    margin: 1rem auto;
    display: block;
  }

  .magazine__split__right figure img {
    max-width: 70%;
    margin: 0 auto;
    display: block;
    box-shadow: 0 0 20px #fff6;
  }

  .magazine__split__right figure figcaption {
    text-align: center;
    width: 100%;
    margin: .5rem 0;
    font-size: .8rem;
  }
}

.magazine__split__fullimage {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 2.5rem);
  position: absolute;
  top: 0;
  left: 0;
}

.magazine__split__fullimage__caption {
  color: #a5a5a5;
  text-align: center;
  background-color: #000;
  width: 100%;
  height: auto;
  padding-top: .3rem;
  padding-bottom: 2.5rem;
  font-size: .9rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.magazine__split__fullimage__caption p {
  max-width: 600px;
  margin: 0 auto;
}

.magazine__split__images {
  text-align: center;
  padding-top: .6rem;
}

.magazine__split__images figure {
  width: 100%;
  margin: 1.5rem auto;
  display: block;
}

.magazine__split__images figure img {
  width: auto;
  max-width: 90%;
  height: auto;
  max-height: 28rem;
}

.magazine__split__images figure figcaption {
  color: #000;
  font-size: .8rem;
}

.main--about {
  box-sizing: border-box;
  background-color: #f5f5f5;
  min-height: calc(100vh - 2.56rem);
  margin-top: 2.5rem;
  padding-bottom: 2rem;
}

@media screen and (max-width: 700px) {
  .main--about {
    margin-top: 30px;
  }
}

.about__intro {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 6rem 1rem;
}

.about__intro p {
  font-size: 3.5rem;
  line-height: 1.2;
}

@media screen and (max-width: 700px) {
  .about__intro {
    margin: 0 auto;
    padding: 5rem 1rem;
  }

  .about__intro p {
    font-size: 1.5rem;
  }
}

.team {
  flex-wrap: wrap;
  padding: 1rem 2rem;
  display: flex;
}

.team__item {
  width: 50%;
  margin: 1rem 0 1.5rem;
  display: flex;
  position: relative;
}

.team__item__image {
  flex-shrink: 0;
  width: 5rem;
  height: 5rem;
}

.team__item__image img {
  object-fit: cover;
  border-radius: 99%;
  width: 100%;
  height: 100%;
  display: block;
}

.team__item__text {
  padding-left: 1rem;
  padding-right: 2rem;
  display: block;
}

@media screen and (min-width: 1500px) {
  .team__item {
    width: 33.3%;
  }
}

@media screen and (max-width: 700px) {
  .team {
    padding: 0 1rem;
  }

  .team__item {
    width: 100%;
  }
}

.alumnis {
  flex-wrap: wrap;
  margin-top: 1rem;
  padding: .5rem 2rem 3rem;
  display: flex;
}

.alumnis h1 {
  margin-bottom: .5rem;
}

.alumnis__block {
  width: 33.3%;
}

.alumnis__item {
  color: #a5a5a5;
}

@media screen and (max-width: 700px) {
  .alumnis {
    padding: 0 1rem;
  }

  .alumnis__block {
    width: 100%;
  }
}

.about__data {
  padding: 1rem 2rem 2rem;
}

@media screen and (max-width: 700px) {
  .about__data {
    padding: 1rem 1rem 2rem;
  }
}

.about__data p {
  margin-top: 1rem;
}

.about__data p:first-child {
  margin-top: 0;
}

.about__data__row {
  justify-content: space-between;
  margin-bottom: 2rem;
  display: flex;
}

.about__data__col {
  flex-grow: 0;
  width: calc(50% - .5rem);
}

.about__data__image img {
  width: 100%;
  height: auto;
  display: block;
}

.about__data__address {
  margin-bottom: 1rem;
}

.about__data__infos {
  flex-wrap: wrap;
  display: flex;
}

.about__data__contact {
  flex-grow: 1;
}

.about__data__table {
  width: 100%;
  margin-top: 1rem;
}

.about__data__table td {
  box-sizing: border-box;
  padding-bottom: .5rem;
}

.about__data__table td p + p {
  margin-top: .5rem;
}

.about__data__table td:first-child {
  padding-right: 2rem;
}

.about__data__text {
  width: 100%;
}

.about__data__map {
  width: 100%;
  height: 100%;
}

.about__data__map iframe {
  -webkit-filter: grayscale(99%);
  -webkit-backface-visibility: hidden;
  border: 0;
  width: 100%;
  height: 100%;
  display: block;
}

@media screen and (max-width: 700px) {
  .about__data__map {
    width: 100%;
    height: 10rem;
    margin-top: 2rem;
    position: relative;
    top: 0;
    right: 0;
  }
}

@media screen and (min-width: 1500px) {
  .about__data__infos {
    display: flex;
  }
}

@media screen and (max-width: 1000px) {
  .about__data__row {
    flex-wrap: wrap;
  }

  .about__data__col {
    width: 100%;
    margin: 1rem auto;
  }

  .about__data__map {
    height: 30rem;
  }
}

@media screen and (max-width: 700px) {
  .about__data__map {
    height: 20rem;
  }
}

.main--partner {
  box-sizing: border-box;
  background-color: #f5f5f5;
  min-height: calc(100vh - 2.56rem);
  margin-top: 2.5rem;
}

@media screen and (max-width: 700px) {
  .main--partner {
    margin-top: 30px;
  }
}

.partner__item {
  padding: 1rem 2.5rem 2rem;
  display: flex;
}

@media screen and (max-width: 700px) {
  .partner__item {
    padding: 1rem 1rem 1.5rem;
  }
}

.partner__item__image {
  object-fit: cover;
  border-radius: 99%;
  width: 7.5rem;
  height: 7.5rem;
  margin-top: .2rem;
}

.partner__item__title {
  flex-grow: 1;
  padding-left: 1.5rem;
  padding-right: 1rem;
}

.partner__item__table {
  flex-grow: 0;
  flex-shrink: 0;
  width: 50%;
}

.partner__item__table table {
  width: 100%;
}

.partner__item__table table td:first-child {
  width: 7.5rem;
}

@media screen and (max-width: 1000px) {
  .partner__item__table {
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .partner__item {
    flex-wrap: wrap;
  }

  .partner__item__image {
    width: 6rem;
    height: 6rem;
  }

  .partner__item__title {
    flex-grow: 0;
    flex-shrink: 0;
    width: calc(100% - 9rem);
    padding-right: 0;
  }

  .partner__item__table {
    width: 100%;
    margin-top: 1rem;
  }
}

.main--table {
  background-color: #f5f5f5;
  min-height: calc(100vh - 2.5rem);
  margin-top: 2.5rem;
  padding-bottom: 4rem;
}

@media screen and (max-width: 700px) {
  .main--table {
    border-top: none;
    min-height: calc(100vh - 2rem);
    margin-top: 2rem;
    padding-top: 2rem;
  }
}

.table-content {
  letter-spacing: 0;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.table-content--publications {
  table-layout: fixed;
}

.table-content--publications th:first-child {
  width: 36%;
}

.table-content--publications th:nth-child(2), .table-content--publications th:nth-child(3) {
  width: 20%;
}

.table-content--publications th:nth-child(4), .table-content--publications th:nth-child(5), .table-content--publications th:nth-child(6) {
  width: 8%;
}

@media screen and (max-width: 700px) {
  .table-content--publications {
    margin-top: 2rem;
  }
}

.table-content--news {
  table-layout: fixed;
}

.table-content--news th:first-child {
  width: 44%;
}

.table-content--news th:nth-child(2) {
  width: 34%;
}

.table-content--news th:nth-child(3) {
  width: 14%;
}

.table-content--news th:nth-child(4) {
  width: 8%;
}

.table-content tr.head {
  border-top: none;
}

@media screen and (max-width: 700px) {
  .table-content tr.head {
    display: none;
  }
}

.table-content th {
  color: #a5a5a5;
  text-align: left;
  padding: .5rem .5rem 2rem 0;
}

.table-content th:first-child {
  padding-left: 2rem;
}

.table-content th:last-child {
  padding-right: 2rem;
}

@media screen and (max-width: 700px) {
  .table-content th {
    padding-top: 1rem;
  }

  .table-content th:first-child {
    padding-left: 1rem;
  }

  .table-content th:last-child {
    padding-right: 1rem;
  }
}

.table-content th a {
  color: inherit;
}

.table-content th.right {
  text-align: right;
}

.table-content th.active {
  color: #000;
}

.table-content td {
  color: inherit;
  text-align: left;
  text-overflow: ellipsis;
  padding: .3rem .5rem .8rem 0;
  overflow: hidden;
}

.table-content td:first-child {
  padding-left: 2rem;
}

.table-content td:last-child {
  padding-right: 2rem;
}

@media screen and (max-width: 700px) {
  .table-content td:first-child {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.table-content td.right {
  text-align: right;
}

.table-content td a, .table-content td a:hover {
  color: inherit;
}

.table-content .icon-active {
  background-color: #a5a5a5;
  border-radius: 99%;
  width: 10px;
  height: 10px;
  display: block;
}

.table-content__parent {
  white-space: nowrap;
  color: #a5a5a5;
  border-top: 1px solid #ccc;
  transition: background-color .2s, border-color .2s, color .2s;
}

.table-content__parent--inactive {
  background-color: #fefefe;
}

.table-content__parent.popup {
  cursor: pointer;
}

.table-content__parent.popup:hover {
  color: #000;
  background-color: #6f9;
}

.table-content__parent.popup:hover .icon-active {
  background-color: #000;
}

.table-content__parent.is-active, .table-content__parent.is-active:hover {
  color: #000;
  background-color: #fff;
}

.table-content__parent.is-active .icon-active {
  background-color: #000;
}

@media screen and (max-width: 700px) {
  .table-content__parent {
    white-space: normal;
  }
}

.table-content__child {
  color: #000;
  white-space: normal;
  background-color: #fff;
  border-top: none;
  display: none;
  overflow: hidden;
}

.table-content__child.is-visible {
  display: table-row;
}

.table-content__child td {
  vertical-align: top;
  box-sizing: border-box;
  padding: 0 1rem 0 0;
}

.table-content__child td .td__inner {
  position: relative;
  overflow: hidden;
}

.table-content__child td .td__inner p:last-child, .table-content__child td .td__inner ul:last-child {
  padding-bottom: 2rem;
}

.table-content__child td table.td__infotable {
  width: 100%;
  max-width: 35rem;
  margin: 1rem 0;
  line-height: 1.4;
}

.table-content__child td table.td__infotable td:first-child {
  width: 12rem;
  padding-left: 0;
}

.table-content__child td table.td__infotable td {
  font-size: .7rem;
}

.table-content__child .morelink {
  color: #888;
  display: block;
}

.table-content__child .morelink:hover {
  color: #000;
}

.table-content__child .morelink:last-child {
  margin-bottom: 1rem;
}

.table-content__child .morelink + p, .table-content__child p + .morelink {
  margin-top: 1rem;
}

.table-content__child p {
  color: inherit;
  letter-spacing: .02em;
  max-width: 35rem;
  font-size: 1rem;
}

.table-content__child p a {
  color: #a5a5a5;
}

.table-content__child p strong {
  font-weight: normal;
}

.table-content__child ul {
  font-size: 1rem;
}

.table-content__child ul a {
  color: #a5a5a5;
}

.table-content__child ul li {
  padding-left: 1.5rem;
  position: relative;
}

.table-content__child ul li:before {
  content: "–";
  position: absolute;
  top: 0;
  left: 0;
}

.table-content__child p + p, .table-content__child p + ul, .table-content__child ul + p {
  margin-top: 1rem;
}

.table-content__child img {
  max-width: 100%;
  max-height: 450px;
  margin-bottom: 1rem;
}

@media screen and (max-width: 700px) {
  .table-content__child img {
    max-height: 250px;
  }
}

.table-content__title {
  width: 40%;
}

.table__mobilesort {
  display: none;
  position: absolute;
  top: 2.9rem;
  right: 1rem;
}

.table__mobilesort:after {
  content: "";
  pointer-events: none;
  background-image: url("../arrow-down.e5cadc9d.png");
  background-size: 16px 9px;
  width: 16px;
  height: 9px;
  display: block;
  position: absolute;
  top: 7px;
  right: 0;
}

@media screen and (max-width: 700px) {
  .table__mobilesort {
    display: block;
  }
}

.table__mobilesort select {
  text-align: right;
  text-align-last: right;
  -webkit-appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  width: 150px;
  height: auto;
  padding-right: 25px;
  font-size: 1rem;
  line-height: 1.4rem;
}

.table__mobilesort select:focus {
  color: #444;
  outline: none;
}

/*# sourceMappingURL=main.css.map */
