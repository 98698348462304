body,
html {
  min-height: 100%;
}
html {
  font-size: 18px;

  @include bp-m() {
    font-size: 15px;
  }
  /*@include bp-l() {
    font-size: 24px;
  }*/

  @media print {
    font-size: 12px;
  }
}
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.3;
  letter-spacing: 0.02em;
}
a {
  text-decoration: none;
  color: $c-grey;

  &:hover {
    color: #000;
  }
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}

.main.is-blurred {
  // filter: blur(10px);
  // -webkit-filter: blur(10px);
}
.main {
  transition: 0.3s -webkit-filter, 0.3s filter;
}

.main--default,
.main--contact {
  background-color: $c-lightgrey;
  min-height: calc(100vh - 2.56rem);
  margin-top: 2.5rem;
  padding: 1rem 2rem 3rem 2rem;
  box-sizing: border-box;
  position: relative;

  @include bp-s() {
    margin-top: 30px;
    padding: 1rem 1rem 2rem 1rem;
    min-height: calc(100vh - 2rem);
  }

  p + p {
    margin-top: 1rem;
  }
  p {
    max-width: 38rem;
  }
  h1 {
  }
  p + h1 {
    margin-top: 2rem;
  }
}

.br-spacer {
  display: inline-block;
  width: 2.5rem;
  width: 2rem;
}

.comma-list {
  display: inline;

  li {
    display: inline;

    &::after {
      content: ", ";
    }
    &:last-child::after {
      content: "";
    }
  }
}

.section-headline {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 2rem;
  color: $c-grey;
  background-color: #fff;

  @include bp-s() {
    padding: 1.5rem 1rem;
  }
}

.videoplayer {
  position: relative;

  &__mute {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background-image: url("../images/icon-mute.svg");
    appearance: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    width: 3rem;
    height: 3rem;
    background-size: 50% auto;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 100%;
    z-index: 10;
    transition: 0.2s background-color;

    &:hover {
      background-color: rgba(230, 230, 230, 0.7);
    }
    span {
      display: none;
    }
    &.active {
      background-image: url("../images/icon-mute-active.svg");
    }
  }
}
