.main--about {
  background-color: $c-lightgrey;
  min-height: calc(100vh - 2.56rem);
  margin-top: 2.5rem;
  padding-bottom: 2rem;
  box-sizing: border-box;

  @include bp-s() {
    margin-top: 30px;
  }
}
.about {
  &__intro {
    text-align: center;
    padding: 6rem 1rem;
    margin: 0 auto;
    max-width: 1200px;

    p {
      font-size: 3.5rem;
      line-height: 1.2;
    }
    @include bp-s() {
      margin: 0 auto;
      padding: 5rem 1rem;

      p {
        font-size: 1.5rem;
      }
    }
  }
}

.team {
  padding: 1rem 2rem;
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: 50%;
    margin: 1rem 0 1.5rem 0;
    position: relative;
    display: flex;

    &__image {
      width: 5rem;
      height: 5rem;
      flex-shrink: 0;

      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 99%;
        object-fit: cover;
      }
    }
    &__text {
      display: block;
      padding-left: 1rem;
      padding-right: 2rem;

    }
  }
  @include bp-l() {
    &__item {
      width: 33.3%;
    }
  }
  @include bp-s() {
    padding: 0 1rem;

    &__item {
      width: 100%;
    }
  }
}

.alumnis {
  padding: 0 2rem;
  margin-top: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;

  h1 {
    margin-bottom: 0.5rem;
  }
  &__block {
    width: 33.3%;
  }
  &__item {
    color: $c-grey;
  }

  @include bp-s() {
    padding: 0 1rem;

    &__block {
      width: 100%;
    }
  }
}

.about__data {
  padding: 1rem 2rem 2rem 2rem;

  @include bp-s() {
    padding: 1rem 1rem 2rem 1rem;
  }

  p {
    margin-top: 1rem;
    &:first-child {
      margin-top: 0;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  &__col {
    width: calc(50% - 0.5rem);
    flex-grow: 0;
  }
  &__image {
    img {
      width: 100%;
      display: block;
      height: auto;
    }
  }
  &__address {
    margin-bottom: 1rem;
  }
  &__infos {
    display: flex;
    flex-wrap: wrap;
  }
  &__contact {
    flex-grow: 1;
  }
  &__table {
    margin-top: 1rem;
    width: 100%;

    td {
      padding-bottom: 0.5rem;
      box-sizing: border-box;

      p + p {
        margin-top: 0.5rem;
      }
      &:first-child {
        padding-right: 2rem;

        @include bp-s() {
          // width: 6rem;
        }
      }
    }
  }
  &__text {
    width: 100%;
  }
  &__map {
    width: 100%;
    height: 100%;

    iframe {
      display: block;
      height: 100%;
      width: 100%;
      border: 0;
      filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale"); /* Firefox 10+ */
      filter: gray; /* IE6-9 */
      -webkit-filter: grayscale(99%); /* Chrome 19+ & Safari 6+ */
      -webkit-backface-visibility: hidden; /* Fix for transition flickering */
    }

    @include bp-s() {
      position: relative;
      width: 100%;
      height: 10rem;
      right: 0;
      top: 0;
      margin-top: 2rem;
    }
  }

  @include bp-l() {
    &__infos {
      display: flex;
    }
  }
  @include bp-m() {
    &__row {
      flex-wrap: wrap;
    }
    &__col {
      width: 100%;
      margin: 1rem auto;
    }
    &__map {
      height: 30rem;
    }
  }
  @include bp-s() {
    &__map {
      height: 20rem;
    }
  }
}

.main--partner {
  background-color: $c-lightgrey;
  min-height: calc(100vh - 2.56rem);
  margin-top: 2.5rem;
  box-sizing: border-box;

  @include bp-s() {
    margin-top: 30px;
  }
}

.partner {
  &__item {
    padding: 1rem 2.5rem 2rem 2.5rem;
    display: flex;

    @include bp-s() {
      padding: 1rem 1rem 1.5rem 1rem;
    }

    &__image {
      width: 7.5rem;
      height: 7.5rem;
      object-fit: cover;
      border-radius: 99%;
      margin-top: 0.2rem;
    }
    &__title {
      padding-left: 1.5rem;
      padding-right: 1rem;
      flex-grow: 1;
    }
    &__table {
      width: 50%;
      flex-grow: 0;
      flex-shrink: 0;

      table {
        width: 100%;

        td:first-child {
          width: 7.5rem;
        }
      }
    }

    @include bp-m() {
      &__table {
        width: 60%;
      }
      &__title {

      }
    }
    @include bp-s() {
      flex-wrap: wrap;

      &__image {
        width: 6rem;
        height: 6rem;
      }
      &__title {
        width: calc(100% - 9rem);
        padding-right: 0;
        flex-shrink: 0;
        flex-grow: 0;
      }
      &__table {
        width: 100%;
        margin-top: 1rem;
      }
    }
  }
}
